import React, { useEffect, useState } from "react"
import axios from "../../http/axios"
import { useCurrentUser } from "../../components/CurrentUserContext"
import { useTranslation } from "react-i18next"
import Link from "gatsby-link"
import HeartIcon from "../../svg/heart-icon-red.inline.svg"

const { addSpaceNumber, changeTitleRout } = require("../../scripts/Utils")

const UserProfileSavedSchoolsSection = () => {
  const { t, i18n } = useTranslation()
  const lang = i18n.language
  const { currentUser } = useCurrentUser()

  const [accountsApplications, setAccountsApplications] = useState([])
  const [savedSummerSchools, setSavedSummerSchools] = useState([])

  useEffect(async () => {
    if (currentUser && currentUser.id) {
      axios
        .get(`/accounts/${currentUser.id}/saved-schools?lang=${lang}`)
        .then(res => {
          setAccountsApplications(res.data)
        })
      axios
        .get(`/accounts/${currentUser.id}/saved-summer-schools?lang=${lang}`)
        .then(res => {
          setSavedSummerSchools(res.data)
        })
    }
  }, [])

  const handleRemoveItem = item => {
    const deleteValue = accountsApplications.filter(a => a.id !== item.id)
    if (currentUser) {
      axios
        .delete(`/accounts/${currentUser.id}/saved-schools/${item.id}`)
        .then(() => {
          setAccountsApplications(deleteValue)
        })
    }
  }

  const handleRemoveItemSummer = item => {
    const deleteValue = savedSummerSchools.filter(a => a.id !== item.id)
    if (currentUser) {
      axios
        .delete(`/accounts/${currentUser.id}/saved-summer-schools/${item.id}`)
        .then(() => {
          setSavedSummerSchools(deleteValue)
        })
    }
  }

  accountsApplications.sort((a, b) => a.name?.localeCompare(b.name))
  savedSummerSchools.sort((a, b) => a.name?.localeCompare(b.name))

  return (
    <>
      {accountsApplications.length > 0 ? (
        <>
          <div className="f-jc container">
            <div className="saved-schools-wrapper">
              <div className="user-profile-title">
                {t("main.savedSchools.saved")}
              </div>
              <div className="saved-schools-list">
                {accountsApplications &&
                  accountsApplications.map(item => {
                    return (
                      <div key={item.id} className="saved-school-item">
                        <Link to={`/${lang}/school/${item?.engName}`}>
                          <img
                            src={item.resolvedCroppedImageUrl}
                            alt={item.title}
                            className="saved-school-image cobham-school"
                          />
                        </Link>
                        <HeartIcon
                          onClick={() => handleRemoveItem(item)}
                          style={{ backgroundColor: `inherit` }}
                          className="delete-icon-compare"
                        />

                        <div className="saved-school-text saved-school-price">
                          {item.name}
                        </div>
                        {item.ageMax ? (
                          <div className="saved-school-text">
                            {item.schoolTypesResolved} · {item.ageMin}–
                            {item.ageMax} {t("main.savedSchools.years")}
                          </div>
                        ) : null}

                        <Link to={`/${lang}/school/${item.resolvedPath}`}>
                          <div className="saved-school-title">{item.title}</div>
                        </Link>
                        {item.settlement ? (
                          <div className="saved-school-text">
                            {item.settlement} · {item.regionResolved}
                          </div>
                        ) : null}
                        <div className="saved-school-text">
                          {item.educationalProgramsResolved}
                        </div>
                        {item.perTermMin > 0 ? (
                          <div className="saved-school-price">
                            £{addSpaceNumber(item.perTermMin)} –{" "}
                            {addSpaceNumber(item.perTermMax)}
                          </div>
                        ) : null}
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {savedSummerSchools.length === 0 && (
            <div className="compare-schools-wrapper no-schools container">
              <div className="compare-titles f-jb">
                <div className="user-profile-title f-js">
                  {t("main.savedSchools.noSaved")}{" "}
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {savedSummerSchools.length > 0 && (
        <div className="f-jc container">
          <div className="saved-schools-wrapper">
            <div className="user-profile-title">
              {t("main.savedSchools.summerSaved")}
            </div>
            <div className="saved-schools-list">
              {savedSummerSchools.map(item => {
                return (
                  <div key={item.id} className="saved-school-item">
                    <Link
                      to={`/${lang}/summer-schools/${changeTitleRout(
                        item?.engName
                      )}`}
                    >
                      <img
                        src={item.resolvedImageUrl}
                        alt={item.title}
                        className="saved-school-image cobham-school"
                      />
                    </Link>
                    <HeartIcon
                      onClick={() => handleRemoveItemSummer(item)}
                      style={{ backgroundColor: `inherit` }}
                      className="delete-icon-compare"
                    />

                    <div className="saved-school-text saved-school-price">
                      {item.name}
                    </div>
                    {item.ageMax ? (
                      <div className="saved-school-text">
                        {item.schoolTypesResolved} · {item.ageMin}–{item.ageMax}{" "}
                        {t("main.savedSchools.years")}
                      </div>
                    ) : null}

                    <Link to={`/${lang}/school/${item.resolvedPath}`}>
                      <div className="saved-school-title">{item.title}</div>
                    </Link>
                    {item.settlement ? (
                      <div className="saved-school-text">
                        {item.settlement} · {item.regionResolved}
                      </div>
                    ) : null}
                    <div className="saved-school-text">
                      {item.educationalProgramsResolved}
                    </div>
                    {item.perTermMin > 0 ? (
                      <div className="saved-school-price">
                        £{addSpaceNumber(item.perTermMin)} –{" "}
                        {addSpaceNumber(item.perTermMax)}
                      </div>
                    ) : null}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default UserProfileSavedSchoolsSection
