import React, { useEffect, useState } from "react"
import { isBrowser } from "./CurrentUserContext"

import { useTranslation } from "react-i18next"

import { navigate } from "gatsby"

const AccessDeniedMessage = props => {
  const { t } = useTranslation()
  return (
    <div className="container denied-access">
      {((props.currentUser &&
        props.currentUser.hasOwnProperty("accountType")) ||
        (!props.userLoading && !props.currentUser)) && (
        <div className="row mt-9">
          <div className="col-lg-3"></div>
          <div className="col-lg-6 denied-access-message-wrapper">
            <div className="warning-message d-flex flex-column justify-content-center align-items-center py-6">
              <h4 className="text-center mb-6">{t("main.accessDenied")}</h4>
              <div
                className="return-button text-center curs-P px-4 py-2"
                onClick={() => {
                  if (isBrowser()) {
                    navigate(`/`)
                  }
                }}
              >
                {t("main.returnToMain")}
              </div>
            </div>
          </div>
          <div className="col-lg-3"></div>
        </div>
      )}
    </div>
  )
}

export default AccessDeniedMessage
