import UserProfileHeaderSection from "../../sections/user_profile_sections/UserProfileHeaderSection"
import UserProfileSavedSchoolsSection from "../../sections/user_profile_sections/UserProfileSavedSchoolsSection"
import * as React from "react"

import { useCurrentUser } from "../../components/CurrentUserContext"

import AccessDeniedMessage from "../../components/AccessDeniedMessage"

import Layout from "../../components/Layout"

const UserProfileSavedPage = () => {
  const { currentUser, userLoading } = useCurrentUser()

  return (
    <Layout isSecondLayout={true}>
      {currentUser && currentUser.accountType === "user" ? (
        <div className="global-wrapper user-saved">
          <UserProfileHeaderSection state5="active" choice_status5="chosen" />
          <UserProfileSavedSchoolsSection />
        </div>
      ) : (
        <AccessDeniedMessage
          currentUser={currentUser}
          userLoading={userLoading}
        />
      )}
    </Layout>
  )
}

export default UserProfileSavedPage
