import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { useTranslation } from "react-i18next"
import UserLoginName from "../UserLoginName"
import ChangeChoiceLanguage from "../../components/ChangeChoiceLanguage"

const UserProfileHeaderSection = props => {
  const { t, i18n } = useTranslation()
  const lang = i18n.language
  const [pathname, setPathname] = useState("")

  useEffect(() => {
    setPathname(window.location.pathname)
  }, [])
  return (
    <div className="header shrinked container px-5">
      <div className="row d-flex justify-content-between align-items-center">
        <div className="logo-menu-block f-ac f-js col">
          <Link to={`/${lang}/`}>
            <div className="logo_full mR-20 shrink">
              <div className="logo_full_block logo_full_block_1">Smart</div>
              <div className="logo_full_block logo_full_block_2">
                Admissions
              </div>
              <div className="logo_full_block logo_full_block_3">Service</div>
              <div className="logo_shrinked_block">
                S&nbsp;&nbsp;&nbsp;A&nbsp;&nbsp;&nbsp;S
              </div>
            </div>
          </Link>
          {/* NAV BAR */}
          <div className="ps-4 user-profile-nav-bar f-ac row w-100">
            <div
              className={`user-nav-option-wrapper f-ac col-md-4 col-lg-3 ${props.choice_status1}`}
            >
              <Link to={`/${lang}/profile`} className="w-100">
                <div
                  className={`user-nav-bar-option text-center w-100 ${props.state1}`}
                >
                  {t("main.userProfileTabs.myProfile")}
                </div>
              </Link>
            </div>
            <div
              className={`user-nav-option-wrapper f-ac col-md-4 col-lg-3 ${props.choice_status5}`}
            >
              <Link to={`/${lang}/profile/saved-schools`} className="w-100">
                <div
                  className={`user-nav-bar-option text-center w-100 ${props.state5}`}
                >
                  {t("main.userProfileTabs.savedSchools")}
                </div>
              </Link>
            </div>
            <div
              className={`user-nav-option-wrapper f-ac col-md-4 col-lg-3 ${props.choice_status6}`}
            >
              <Link to={`/${lang}/profile/compare-schools`} className="w-100">
                <div
                  className={`user-nav-bar-option text-center w-100 ${props.state6}`}
                >
                  {t("main.userProfileTabs.compareSchools")}
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div className="col-lg-4 login-panel f-ac f-je right-side-buttons py-4">
          <UserLoginName pathName={pathname} />
          <ChangeChoiceLanguage />
        </div>
      </div>
    </div>
  )
}

export default UserProfileHeaderSection
